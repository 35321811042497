import erc1155Abi from '../abi/ERC1155'
import {
  confirmUserAccount,
  createUserAccount,
  mintNft,
} from './cloudFunctions'
import { getUserByUid } from './firebase'
import { publicClient } from './publicClient'

export async function handleUserAccountCreation(
  email: string,
  uid: string,
  bearerToken: string,
) {
  const taskStatus = await createUserAccount(email, uid, bearerToken)

  const status = taskStatus === 'EXECUTED' ? 'SUCCESS' : 'FAILED'

  if (status === 'SUCCESS') {
    const userConfirmed = await confirmUserAccount(uid, status, bearerToken)
    // const celebratoryEmails = await getDocFromFirestore('nfts', 'celebratory')
    // const parsedCelebratoryEmails = JSON.parse(celebratoryEmails.emails)

    // const userEmailIsCelebratory = parsedCelebratoryEmails.includes(email)
    const currentUserData = await getUserByUid(uid)
    const aaAddress = currentUserData.data()?.aa_address

    try {
      const userBalance = await (publicClient as any).readContract({
        address: process.env.REACT_APP_NFT_ENROLLMENT_ADDRESS,
        abi: erc1155Abi,
        functionName: 'balanceOf',
        args: [aaAddress, '1'],
      })
      if (!userConfirmed) return false

      if (Number(userBalance.toString()) === 0) {
        //TODO mintNft must return transactionReceipt
        await mintNft(uid, bearerToken)
      }
      return true
    } catch (e) {
      return false
    }
  } else return false
}

export async function getUserBalance(
  address: string,
  uid: string,
  bearerToken: string,
) {
  const userBalance = await (publicClient as any).readContract({
    address: process.env.REACT_APP_NFT_ENROLLMENT_ADDRESS,
    abi: erc1155Abi,
    functionName: 'balanceOf',
    args: [address, '1'],
  })

  if (Number(userBalance.toString()) === 0) {
    await mintNft(uid, bearerToken)
  }
}
