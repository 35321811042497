import { Client } from '@knobs-dev/indid-core-sdk'

export async function createUserAccount(
  email: string,
  uid: string,
  bearerToken: string,
) {
  const createUserAccountUrl = process.env.REACT_APP_CREATE_USER_ACCOUNT || ''
  const createUserAccountOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify({ email, uid }),
  }

  const createUserResponse = await fetch(
    createUserAccountUrl,
    createUserAccountOptions,
  )
  const createUserData = await createUserResponse.json()

  const clientUser = await Client.init(
    process.env.REACT_APP_RPC_ENDPOINT!,
    process.env.REACT_APP_INDID_CORE_API_KEY!,
    process.env.REACT_APP_INDID_ENV == 'PROD'
      ? undefined
      : { overrideBackendUrl: 'https://api.dev.indid.io' },
  )

  return await clientUser.waitTask(createUserData.taskId)
}

export async function confirmUserAccount(
  uid: string,
  status: string,
  bearerToken: string,
) {
  const confirmUserAccountUrl = process.env.REACT_APP_CONFIRM_USER_ACCOUNT || ''
  const confirmUserAccountOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify({ uid, status }),
  }

  const confirmUserResponse = await fetch(
    confirmUserAccountUrl,
    confirmUserAccountOptions,
  )

  if (confirmUserResponse.status !== 200) {
    throw new Error('Failed to confirm user account')
  } else {
    return true
  }
}

export async function mintNft(uid: string, bearerToken: string) {
  const mintNftUrl = process.env.REACT_APP_MINT_CERTIFICATE || ''
  const mintNftOptions = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${bearerToken}`,
    },
    body: JSON.stringify({ uid }),
  }

  const mintNftResponse = await fetch(mintNftUrl, mintNftOptions)
  const mintNftData = await mintNftResponse.json()
  const clientUser = await Client.init(
    process.env.REACT_APP_RPC_ENDPOINT!,
    process.env.REACT_APP_INDID_CORE_API_KEY!,
    process.env.REACT_APP_INDID_ENV == 'PROD'
      ? undefined
      : { overrideBackendUrl: 'https://api.dev.indid.io' },
  )
  await clientUser.waitOP(mintNftData.userOpHash) //TODO returns userOp.receipt

  return
}
