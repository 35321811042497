import React, { FC, useState } from 'react'
import { cva, cx, VariantProps } from 'cva'

const cardTitle = cva(
  ['truncate', 'text-lg', 'font-bold', 'transition-colors', 'duration-300'],
  {
    variants: {
      intent: {
        default: ['text-black'],
        hovered: ['text-blue-dark'],
      },
      defaultVariants: {
        intent: 'default',
      },
    },
  },
)

type CardProps = VariantProps<typeof cardTitle> & {
  nft: {
    title: string
    subtitle: string
    image: string
    balance: number
  }
  onClick?: () => void
}

const Card: FC<CardProps> = ({
  nft: { title, subtitle, image, balance },
  onClick,
}) => {
  const [isHovered, setIsHovered] = useState(false)

  return (
    <div
      className={`relative aspect-square h-full w-full cursor-pointer overflow-hidden rounded-2xl bg-white shadow-[0px_30px_40px_0px_rgba(0,0,0,0.1)]`}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={onClick}
    >
      <div className='h-[calc(100%-5rem)] w-full'>
        <img
          src={image}
          alt={title}
          className={`h-full w-full object-cover ${
            balance === 0 && 'grayscale-[90%]'
          }`}
        />
      </div>

      <div className='h-20 p-4'>
        <h2
          className={cx(
            cardTitle({ intent: isHovered ? 'hovered' : 'default' }),
          )}
        >
          {title}
        </h2>
        <p className='truncate text-black'>{subtitle}</p>
      </div>
    </div>
  )
}

export default Card
