const Tos = ({ isPopup = false }: { isPopup?: boolean }) => {
  const SectionTitle = ({ text, id }: { text: string; id?: string }) => {
    const classname = isPopup
      ? 'w-full py-2 font-semibold text-blue-dark text-sm'
      : 'my-2 text-2xl'
    return (
      <h4 id={id} className={classname}>
        {text}
      </h4>
    )
  }

  const Paragraph = ({ text }: { text: string }) => {
    const classname = isPopup
      ? 'text-xs font-normal leading-relaxed text-gray-dark'
      : 'mb-5'
    return <p className={classname}>{text}</p>
  }

  const classname = isPopup
    ? 'text-xs font-normal leading-relaxed text-gray-dark'
    : 'mb-5'

  return (
    <div id='tos-container'>
      {!isPopup && (
        <h1 className='mb-5 text-5xl font-bold'>
          TERMINI E CONDIZIONI DI SERVIZIO PER L’UTENTE
        </h1>
      )}
      <p className={classname}>
        I presenti termini e condizioni di servizio (o “Termini di Servizio”)
        disciplinano l’accesso alla piattaforma digitale reperibile al sito web{' '}
        <a
          className='underline underline-offset-2'
          href='https://collectibles.luiss.it'
        >
          collectibles.luiss.it
        </a>{' '}
        (“Piattaforma”) dell’Università Luiss Guido Carli, nonché le regole di
        utilizzo del Wallet assegnato dall’Università all’Utente, tranne nei
        casi di esplicita indicazione circa l’applicazione di termini diversi
        dai presenti.
      </p>{' '}
      <p className={classname + ' ' + 'mt-5'}>
        Cliccando sul pulsante “accetto”, navigando sulla Piattaforma,
        effettuando l’accesso, o utilizzando in altro modo il servizio della
        Piattaforma e del Wallet, l’Utente dichiara: (i) di aver letto, compreso
        e accettato di essere vincolato dai presenti Termini di Servizio, (ii)
        di avere tutti i diritti e poteri necessari per concludere il presente
        contratto e dare esecuzione piena ed efficace al suo contenuto.
      </p>
      <SectionTitle text='SOMMARIO' />
      <table className={classname + ' ' + 'w-full'}>
        <tbody>
          <tr className='border py-2'>
            <td>1.</td>
            <a href='#1'>DEFINIZIONI</a>
          </tr>
          <tr className='border py-2'>
            <td>2.</td>
            <a href='#2'>OGGETTO DEL PRESENTE DOCUMENTO</a>
          </tr>
          <tr className='border py-2'>
            <td>3.</td>
            <a href='#3'>CONCLUSIONE DEL CONTRATTO, ACCETTAZIONE E MODIFICHE</a>
          </tr>
          <tr className='border py-2'>
            <td>4.</td>
            <a href='#4'>UTILIZZO DELLA PIATTAFORMA E DEL WALLET</a>
          </tr>
          <tr className='border py-2'>
            <td>5.</td>
            <a href='#5'>OBBLIGHI E RESPONSABILITÀ DELL’UTENTE</a>
          </tr>
          <tr className='border py-2'>
            <td>6.</td>
            <a href='#6'>FACOLTÀ DELL’UNIVERSITÀ, SOSPENSIONE</a>
          </tr>
          <tr className='border py-2'>
            <td>7.</td>
            <a href='#7'>LIMITAZIONE DI RESPONSABILITÀ</a>
          </tr>
          <tr className='border py-2'>
            <td>8.</td>
            <a href='#8'>MANLEVA</a>
          </tr>
          <tr className='border py-2'>
            <td>9.</td>
            <a href='#9'>INFORMAZIONI RISERVATE</a>
          </tr>
          <tr className='border py-2'>
            <td>10.</td>
            <a href='#10'>PROPRIETÀ INTELLETTUALE</a>
          </tr>
          <tr className='border py-2'>
            <td>11.</td>
            <a href='#11'>PROPRIETÀ DEI CRYPTO ASSET</a>
          </tr>
          <tr className='border py-2'>
            <td>12.</td>
            <a href='#12'>PRIVACY POLICY & COOKIE</a>
          </tr>
          <tr className='border py-2'>
            <td>13.</td>
            <a href='#13'>RINUNCIA</a>
          </tr>
          <tr className='border py-2'>
            <td>14.</td>
            <a href='#14'>NULLITÀ PARZIALE</a>
          </tr>
          <tr className='border py-2'>
            <td>15.</td>
            <a href='#15'>COMUNICAZIONI E ALTRE DISPOSIZIONI</a>
          </tr>
          <tr className='border py-2'>
            <td>16.</td>
            <a href='#16'>LEGGE APPLICABILE E FORO COMPETENTE</a>
          </tr>
        </tbody>
      </table>
      <ol className='list-decimal'>
        <li className={isPopup ? 'text-sm' : ''}>
          <span>
            <SectionTitle id='1' text='DEFINIZIONI' />
          </span>
          <Paragraph
            text='
        I seguenti termini hanno il significato qui di seguito indicato,
        restando inteso che i termini definiti al plurale si intendono definiti
        anche al singolare e viceversa:'
          />
          <table className={classname}>
            <tbody>
              <tr className={`${!isPopup && 'flex'} justify-between py-2`}>
                <td className='min-w-[30%] pl-2 italic'>TERMINI DI SERVIZIO</td>
                <td>
                  indica i presenti termini e condizioni di utilizzo della
                  Piattaforma e del Wallet da parte dell’Utente;
                </td>
              </tr>
              <tr className={`${!isPopup && 'flex'} justify-between py-2`}>
                <td className='min-w-[30%] pl-2 italic'>UNIVERSITÀ</td>
                <td>
                  indica l’Università Luiss Guido Carli, con sede in Viale Pola
                  12, 00198 Roma;
                </td>
              </tr>
              <tr className={`${!isPopup && 'flex'} justify-between py-2`}>
                <td className='min-w-[30%] pl-2 italic'>UTENTE</td>
                <td>
                  indica, a titolo esemplificativo, docenti, personale tecnico
                  amministrativo; dottorandi; borsisti di ricerca; assegnisti di
                  ricerca, specializzandi, studenti iscritti, collaboratori, e/o
                  partner dell’Università, che possono accedere alla Piataforma
                  tramite le credenziali fornite dall’Università;
                </td>
              </tr>
              <tr className={`${!isPopup && 'flex'} justify-between py-2`}>
                <td className='min-w-[30%] pl-2 italic'>PIATTAFORMA</td>
                <td>
                  indica la piattaforma reperibile al sito
                  collectibles.luiss.it, sviluppata dall’Università Luiss Guido
                  Carli, il cui servizio permette agli Utenti (i) accedere a un
                  Wallet creato e assegnato dall’Università, tramite credenziali
                  fornite dalla stessa; (ii) ricevere e conservare Cypto Asset
                  all’interno del Wallet assegnato dall’Università.
                </td>
              </tr>
              <tr className={`${!isPopup && 'flex'} justify-between py-2`}>
                <td className='min-w-[30%] pl-2 italic'>WALLET</td>
                <td>
                  indica il servizio di portafoglio digitale collegato alla
                  tecnologia blockchain ospitato all’interno della Piattaforma e
                  assegnato all’Utente per conservare i Crypto Asset emessi
                  dall’Università;
                </td>
              </tr>
              <tr className={`${!isPopup && 'flex'} justify-between py-2`}>
                <td className='min-w-[30%] pl-2 italic'> CRYPTO ASSET</td>
                <td>
                  indica, a titolo esemplificativo e non esaustivo, i digital
                  collectibles, gli attestati di laurea, i certificati di
                  partecipazione a corsi, le credenziali per la certificazione
                  delle competenze, emessi dall’Università attraverso l’utilizzo
                  di strumenti Web 3.0 (es. token, NFT, verifiable credentials).
                </td>
              </tr>
            </tbody>
          </table>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='2' text='OGGETTO DEL PRESENTE DOCUMENTO' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                I presenti Termini di Servizio costituiscono un contratto tra
                l’Università e l’Utente. L’Utente è responsabile della presa
                visione dei presenti Termini di Servizi, la cui versione più
                aggiornata è consultabile al link{' '}
                <a
                  className='underline underline-offset-2'
                  href='/legals/terms-of-service'
                >
                  collectibles.luiss.it/legals/terms-of-service
                </a>{' '}
                .
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                I presenti Termini di Servizio disciplinano l’utilizzo e
                l’accesso alla Piattaforma dell’Università Luiss Guido Carli
                nonché l’utilizzo e l’accesso al Waller assegnato
                dall’Università. Tramite il servizio offerto dalla Piattaforma,
                gli Utenti hanno infatti la facoltà di i) accedere a un Wallet
                creato e assegnato dall’Università, tramite credenziali fornite
                dalla stessa (ad esempio, un codice utente associato
                all’indirizzo e-mail appartenente al dominio “@luiss.it” e la
                relativa password); ii) ricevere e conservare Cypto Asset
                all’interno del Wallet assegnato dall’Università;
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente riconosce di aver letto, compreso e accettato i
                presenti Termini di Servizio, e che i presenti Termini di
                Servizio includono ogni nota, avviso legale, informativo o
                disclaimer, compresa l’Informativa Privacy e la Cookie Policy, e
                rappresentano e costituiscono la manifestazione completa degli
                accordi raggiunti fra le parti e annullano e sostituiscono
                qualsiasi eventuale diversa e precedente pattuizione, anche
                verbale, tra le stesse intercorsa, nonché ogni altra
                comunicazione avvenuta tra le stesse relativa all’accesso e
                all’utilizzo della Piattaforma e del Wallet.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle
            id='3'
            text='CONCLUSIONE DEL CONTRATTO, ACCETTAZIONE E MODIFICHE'
          />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente riconosce che accedendo alla Piattaforma sta
                concludendo un accordo vincolante da un punto di vista legale
                con l’Università disciplinato dai presenti Termini di Servizio,
                che accetta espressamente.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                A tal proposito, l’Utente si dichiara pienamente consapevole che
                l’accettazione per mezzo di strumenti elettronici costituisce un
                atto di consenso valido e vincolante. Al medesimo regime si
                considerano assoggettate eventuali manifestazioni di assenso o
                di presa visione riguardanti comunicazioni o notifiche da parte
                dell’Università aventi ad oggetto modificazione, revisione o
                cancellazione di policies, procedure o paragrafi dei presenti
                Termini di Servizio.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente riconosce, infatti, che i servizi offerta sulla
                Piattaforma sono caratterizzati da elevata complessità tecnica e
                normativa, e da continue evoluzioni tecnologiche, e
                conseguentemente potrà sorgere la necessità che l’Università
                adegui l’organizzazione e/o la struttura tecnica e funzionale
                dei servizi offerti tramite la Piattaforma. L’Utente accetta
                dunque che policies, procedure o paragrafi dei presenti Termini
                di Servizio potrebbero subire modificazioni, revisioni o
                cancellazioni. L’Università comunicherà all’Utente le eventuali
                modifiche sopra indicate, che dovranno essere accettate nel
                termine di 30 giorni dalla loro comunicazione, salvo che
                l’Utente decida di recedere senza conseguenze entro lo stesso
                termine.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Il presente accordo, così come concluso, sarà archiviato e messo
                a disposizione dell’Utente secondo le modalità indicate nella
                Piattaforma, oltre a essere salvabile in locale in formato .pdf.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’università invierà via e-mail ricevuta dell’accettazione
                dell’Utente al momento del primo accesso alla Piattaforma,
                contenente un riepilogo dei Termini di Servizio applicabili, le
                informazioni relative alle caratteristiche essenziali del
                servizio della Piattaforma.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='4' text='UTILIZZO DELLA PIATTAFORMA E DEL WALLET' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Per accedere e utilizzare le funzionalità della Piattaforma,
                l’Utente è tenuto ad inserire determinati dati, quali nome
                utente, indirizzo e-mail, password ed eventuali altri dati
                indicati nel form di accesso, come forniti dall’Università.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Una volta completato il primo accesso alla Piattaforma, l’Utente
                potrà accedere al Wallet assegnatogli dall’Università.
                Attraverso il Wallet, l’Utente avrà a disposizione una vera e
                propria identità digitale su blockchain, all’interno della quale
                conservare i Crypto Asset emessi dall’Università al fine di
                costruire un proprio storico verificabile, dimostrabile e
                certificato. La tecnologia blockchain, infatti, si serve di un
                registro condiviso, distribuito, replicabile, accessibile
                simultaneamente, architetturalmente decentralizzato su basi
                crittografiche, tale da consentire la registrazione, la
                convalida, l’aggiornamento e l’archiviazione di dati sia in
                chiaro che ulteriormente protetti da crittografia verificabili
                da ciascun partecipante, non alterabili e non modificabili.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente è tenuto ad adottare misure adeguate e soddisfacenti
                per evitare l’uso improprio o il furto delle credenziali di
                accesso nonché a mantenere un adeguato livello di sicurezza e
                controllo su tutti i dispositivi, gli articoli, gli ID, le
                password e i numeri/codici di identificazione personale
                utilizzati per accedere al Wallet assegnato dall’Università.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Gli Utenti hanno l’obbligo di informare immediatamente
                l’Università qualora ritengano che il Wallet associato alla
                Piattaforma sia stato oggetto di furto o uso illecito da parte
                di terzi. L’Università, in ogni caso, non sarà responsabile in
                nessun caso dell’eventuale uso fraudolento del Wallet
                dell’Utente da parte di terzi.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Università raccomanda gli Utenti di segnalare immediatamente
                alle autorità di polizia l’eventuale furto, appropriazione
                indebita o presunto uso improprio del proprio account. Se
                necessario, l’Università si impegna a collaborare con l’Utente e
                le autorità competenti.
              </p>
            </li>
          </ol>
        </li>

        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='5' text='OBBLIGHI E RESPONSABILITÀ DELL’UTENTE' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente è tenuto a utilizzare il Wallet assegnato
                dall’Università solo per attività didattica, di ricerca,
                amministrativa e per altre attività strumentali o correlate ai
                fini istituzionali dell’Università, nel rispetto di quanto
                disposto dalla normativa vigente e comunque senza recar danno o
                pregiudizio all’Università medesima o a terzi.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente è tenuto, altresì, alla regolare consultazione del
                proprio Wallet e dei Crypto Asset emessi dall’Università.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente non può utilizzare il Wallet in modo improprio,
                illegittimo, ingannevole o fraudolento. A titolo meramente
                esemplificativo, è fatto divieto all’Utente di utilizzare il
                Wallet assegnato dall’Università con modalità o per finalità non
                consentite dalla Legge, ovvero per compiere attività che possano
                compromettere la sicurezza dell’Università e/o di terzi.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Salvo che sia consentito dall’Università e/o dalla natura
                tecnica dei Crypto Asset, l’Utente non può cedere o alienare a
                terzi i Crypto Asset emessi dall’Università e collezionati
                all’interno del Wallet.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Salvo che sia consentito dall’Università e/o dalla natura
                tecnica dei Crypto Asset, l’Utente non può cedere o alienare a
                terzi i Crypto Asset emessi dall’Università e collezionati
                all’interno del Wallet.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Le credenziali di accesso assegnate all’Utente dall’Università
                sono strettamente personali e non possono essere cedute,
                trasmesse, comunicate o concesse dall’Utente a terzi.
                L’Università, in ogni caso, non sarà responsabile in nessun caso
                dell’eventuale uso del Wallet dell’Utente da parte di terzi
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente non può utilizzare l’identità di altri e/o accedere
                alla Piattaforma usando strumenti automatizzati (come bot di
                raccolta, robot, spider o scraper).
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente è responsabile in via esclusiva della veridicità,
                liceità, correttezza e diritto all’utilizzo del materiale o
                delle informazioni da lui trasmesse tramite la Piattaforma.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='6' text='FACOLTÀ DELL’UNIVERSITÀ, SOSPENSIONE' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Qualora l’Università rilevi violazioni da parte dell’Utente
                rispetto a quanto previsto nei presenti Termini di Servizio,
                potrà, a proprio insindacabile giudizio e senza che ciò comporti
                obblighi risarcitori in favore dell’Utente (i) cancellare o
                sospendere l’utenza di acceso alla Piattaforma; (ii) negare
                all’Utente successivi accessi alla Piattaforma; (iii)
                intraprendere nei confronti dell’Utente ogni eventuale azione a
                propria tutela, ivi incluse quelle volte a ottenere il
                risarcimento dei danni. L’Utente accetta senza riserve.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Università potrà modificare, aggiornare, sospendere, limitare
                o interrompere in qualsiasi momento, e senza responsabilità nei
                confronti dell’Utente, il servizio della Piattaforma. All’Utente
                sarà in ogni caso garantita la conservazione dei Crypto Asset
                collezionati all’interno del Wallet.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                In tutti i casi previsti dal presente articolo, l’Utente sarà
                ritenuto responsabile degli eventuali danni patiti
                dall’Università.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='7' text='LIMITAZIONE DI RESPONSABILITÀ' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Fatto salvo il caso di dolo o colpa grave, l’Utente riconosce e
                accetta che l’Università non sarà in alcun modo responsabile per
                eventuali danni, perdite, oneri e spese o costi, diretti o
                indiretti, incluse eventuali spese legali subite e/o sopportate
                dall’Utente in connessione con la Piattaforma.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Università si impegna a fare quanto possibile per assicurare
                che l’accesso alla Piattaforma sia fornito senza interruzioni e
                che ogni trasmissione di dati avvenga senza errori. Tuttavia, a
                causa della natura della rete, l’accesso ininterrotto e
                l’assenza di errori nella trasmissione non possono essere
                garantiti. L’Università non potrà essere, pertanto, considerata
                responsabile per errori, omissioni, interruzioni, cancellazioni,
                difetti, ritardi operativi o di trasmissione, guasti delle linee
                di comunicazione, furto, distruzione, accesso non autorizzato o
                alterazione di qualsiasi contenuto inerente alla Piattaforma,
                salvo che ciò non sia dovuto a dolo o colpa grave.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente, con i presenti Termini di Servizio accetta di essere a
                conoscenza che l’accesso alla Piattaforma potrebbe anche essere
                occasionalmente sospeso o limitato per consentire
                l’effettuazione di lavori di riparazione, manutenzione,
                aggiornamenti o l’introduzione di nuove attività o servizi.{' '}
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Università non sarà responsabile per qualsiasi ritardo o
                inadempimento agli obblighi previsti dai presenti Termini di
                Servizio qualora il ritardo o l’inadempimento derivino da caso
                fortuito o da cause di forza maggiore.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Le limitazioni ed esclusioni nella presente clausola si
                applicano nella misura massima consentita dalla Legge.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='8' text='MANLEVA' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                In caso di violazione degli obblighi a carico dell’Utente ai
                sensi dei presenti Termini di Servizio, l’Utente sarà tenuto a
                manlevare e tenere indenne l’Università, i suoi dipendenti e
                collaboratori, i suoi aventi causa e i suoi partner da e contro
                qualsiasi contestazione, costo, azione legale, controversia o
                pretesa e da qualsiasi perdita, danno, responsabilità,
                provvedimento, sanzione, interesse e spesa (inclusi, a titolo
                esemplificativo gli onorari legali) derivanti da contestazioni,
                azioni legali, accertamenti, indagini, inchieste o altri
                procedimenti promossi da qualsiasi soggetto in relazione a (i)
                un accertato o possibile suo inadempimento delle dichiarazioni o
                degli obblighi e delle responsabilità previste dai presenti
                Termini di Servizio; (ii) accertate o possibili lesioni o
                violazioni di diritti di terzi o delle Leggi applicabili
                derivanti dall’utilizzo della Piattaforma e/o del Wallet da
                parte dell’Utente in maniera non conforme ai presenti Termini di
                Servizio; (iii) più in generale, l'utilizzo dei servizi della
                Piattaforma e in maniera non conforme ai presenti Termini di
                Servizio.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='9' text='INFORMAZIONI RISERVATE' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente riconosce che tutte le informazioni, i dati, le
                conoscenze, il know-how, gli studi, i metodi di ricerca, i
                procedimenti, le formule, le idee, i progetti, i disegni, i
                marchi, le relazioni tecniche, i software, i codici sorgente e
                quant’altro aventi ad oggetto o comunque relative, direttamente
                e/o indirettamente alla Piattaforma, che sono qualificate come
                riservate dall’Università ovvero, in ogni caso, la cui non
                autorizzata diffusione può essere pregiudizievole per gli
                interessi dell’Università, sono da qualificarsi come riservate.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Le informazioni riservate dell’Università, come sopra definite,
                hanno natura confidenziale e riservata e, pertanto, l’Utente si
                impegna a non utilizzare o divulgare a terzi, in qualunque modo
                e con qualunque mezzo, per finalità diverse da quelle di cui ai
                presenti TOS. L’obbligo di riservatezza che precede non riguarda
                le informazioni che sono di dominio pubblico.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente si impegna a proteggere la riservatezza delle
                informazioni riservate dell’Università con la stessa cura con la
                quale protegge la riservatezza delle proprie informazioni
                riservate e comunque con la diligenza professionale che compete
                nel proteggere tali informazioni.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Al di fuori delle finalità previste nei presenti Termini di
                Servizio, le informazioni riservate dell’Università non potranno
                essere copiate o riprodotte senza il previo consenso scritto
                dell’Università.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='10' text='PROPRIETÀ INTELLETTUALE' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                I materiali presenti o resi disponibili dall’Università
                all’Utente attraverso la Piattaforma, nonché i marchi, disegni,
                modelli, know-how, testi, grafiche, disegni, loghi, immagini,
                file audio, raccolte di dati e software, elenchi o altre banche
                dati presenti sulla Piattaforma, sono di proprietà esclusiva
                della Piattaforma e/o di terzi che l’Università sfrutta in base
                a licenze, e sono protetti dalle Leggi italiane e dalle Leggi
                internazionali in materia di diritto d’autore, diritti sulla
                proprietà intellettuale, diritto industriale e diritti sulle
                banche dati.
              </p>
            </li>

            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                In nessun caso l’Utente potrà acquisire alcun diritto di
                proprietà industriale e/o intellettuale o altro diritto
                proprietario sulla Piattaforma in forza dei presenti Termini di
                Servizio inclusi – a titolo esemplificativo e non esaustivo –
                diritti su brevetti, modelli, know-how, design, marchi,
                copyright o diritti su qualsiasi informazione o segreto
                commerciale relativi alla Piattaforma e/o alle informazioni
                riservate come definite nell’articolo che precede.
              </p>
            </li>

            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Successivamente alla cessazione, per qualsiasi ragione
                intervenuta, del rapporto intercorrente tra l’Utente e
                l’Università, è fatto assoluto divieto all’Utente di pubblicare,
                produrre o preparare lavori derivati, distribuire copie, rendere
                disponibili a terzi, far operare o, comunque, avvalersi di
                qualsiasi informazione riservata, come definite nell’articolo
                precedente.
              </p>
            </li>

            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Utente prende atto ed accetta che nessuna delle disposizioni
                dei presenti Termini di Servizio può inficiare alcun diritto o
                alcun ricorso a forme di tutela e rimedi di cui l’Università può
                disporre ai sensi di qualsiasi Legge applicabile a protezione
                della proprietà industriale e/o intellettuale o di qualsiasi
                altro diritto proprietario dell’Università.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='11' text='PROPRIETÀ DEI CRYPTO ASSET' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                La proprietà del Wallet e di tutti i cryptoasset in esso
                contenuti è e resta dell’Università. In caso di cessazione del
                rapporto tra l’Utente e l’Università, a qualsiasi titolo
                intervenuta, la titolarità del Wallet e dei materiali in esso
                contenuti restano in capo all’Università, che potrà liberamente
                disporne senza dover comunicare alcunché all’Utente.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='12' text='PRIVACY POLICY & COOKIE' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                La Privacy Policy della Piattaforma è reperibile al seguente
                link:{' '}
                <a
                  className='underline underline-offset-2'
                  href='/legals/privacy'
                >
                  collectibles.luiss.it/legals/privacy
                </a>{' '}
              </p>
            </li>

            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                La Cookie Policy della Piattaforma è reperibile al seguente
                link:{' '}
                <a
                  className='underline underline-offset-2'
                  href='/legals/cookies'
                >
                  collectibles.luiss.it/legals/cookies
                </a>{' '}
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='13' text='RINUNCIA' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                In nessun caso il mancato esercizio da parte dell’Università del
                proprio diritto di agire nei confronti dell’Utente inadempiente
                ai presenti Termini d’Uso potrà essere interpretato o
                considerato quale forma di rinuncia ai diritti della stessa,
                inclusi quelli di agire.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='14' text='NULLITÀ PARZIALE' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Se una delle disposizioni dei Termini d’Uso fosse giudicata
                nulla o inapplicabile da una corte o da altra autorità
                competente, le parti concordano sin da ora che le rimanenti
                disposizioni dei Termini d’Uso rimarranno in vigore e
                continueranno a rimanere pienamente valide ed efficaci.
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='15' text='COMUNICAZIONI E ALTRE DISPOSIZIONI' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                I diritti e i rimedi qui inclusi non hanno carattere esclusivo,
                ma si aggiungono agli altri diritti e rimedi disponibili secondo
                la legge applicabile.
              </p>
            </li>

            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                Per ogni necessità, l’Utente può contattare i Fornitori ai
                seguenti indirizzi:{' '}
                <a href='mailto:dpo@luiss.it'>dpo@luiss.it</a>
              </p>
            </li>
          </ol>
        </li>
        <li className={isPopup ? 'text-sm' : ''}>
          <SectionTitle id='16' text='LEGGE APPLICABILE E FORO COMPETENTE' />
          <ol className='innerOrderedList'>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                I presenti Termini d’Uso sono sottoposti alla Legge italiana.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                I presenti Termini d’Uso sono sottoposti alla giurisdizione
                dell’Autorità Giudiziaria italiana.
              </p>
            </li>
            <li className={isPopup ? 'text-sm' : ''}>
              <p className={classname}>
                L’Autorità Giudiziaria di Roma sarà la sola territorialmente
                competente a decidere qualsiasi eventuale controversia che
                dovesse insorgere fra le parti, con esclusione di ogni altro
                Foro, alternativo o concorrente.
              </p>
            </li>
          </ol>
        </li>
        <p className={isPopup ? classname + ' mt-5 w-full' : ''}>
          Ai sensi e per gli effetti dell’art. 1341 e 1342 del Codice civile,
          l’Utente, previa lettura delle disposizioni contenute nei presenti
          Termini d’uso, dichiara di approvare specificamente i seguenti
          articoli: 3. (CONCLUSIONE DEL CONTRATTO, ACCETTAZIONE E MODIFICHE), 4.
          (UTILIZZO DELLA PIATTAFORMA A DEL WALLET), 5. (OBBLIGHI E
          RESPONSABILITA’ DELL’UTENTE), 6. (FACOLTA’ DELL’UNIVERSITA’,
          SOSPENSIONE), 7. (LIMITAZIONE DI RESPONSABILITA’), 8. (MANLEVA), 11.
          (PROPRIETA’ DEI CRYPTO ASSET), 13. (RINUNCIA), 16. (LEGGE APPLICABILE
          E FORO COMPETENTE)
        </p>
      </ol>
    </div>
  )
}

export default Tos
