const Cookies = () => {
  const SectionTitle = ({ text }: { text: string }) => (
    <h4 className='mb-2 text-2xl'>{text}</h4>
  )

  const Paragraph = ({ text }: { text: string }) => (
    <p className='mb-5'>{text}</p>
  )

  return (
    <>
      <h1 className='mb-5 text-5xl font-bold'>
        Informativa sul trattamento dei dati personali (artt. 13 e 14 Reg. UE
        2016/679)
      </h1>

      <SectionTitle text='Premessa' />
      <Paragraph
        text='La Luiss, Libera Università Internazionale degli Studi Sociali Guido Carli (di seguito Luiss), è un’Università autonoma che offre un modello formativo avanzato.
        La presente informativa descrive le caratteristiche dei trattamenti svolti dalla Luiss sui dati personali conferiti da coloro che si vedranno attribuito ed utilizzeranno un wallet dedicato ai digital assets e creato da Luiss per i propri utenti, evidenziando i diritti che la normativa riserva loro. Il wallet dedicato ai digital assets di Luiss è uno strumento che l’ateneo mette a disposizione dei propri utenti, su cui ricevere, conservare e scambiare digital assets, intesi come oggetti digitali unici, creati e distribuiti attraverso tecnologia blockchain e DLT. Ogni wallet ha un indirizzo crittografico unico e viene attribuito in maniera univoca ad un utente, che potrà utilizzarlo per ricevere e conservare i digital assets creati da Luiss e dai suoi partner.
        L’informativa viene periodicamente aggiornata, per adattarla alla normativa vigente o alle nuove modalità d trattamento dei dati personali.'
      />

      <SectionTitle text='Quali dati personali raccogliamo?' />
      <Paragraph text='Il Titolare del trattamento raccoglie e tratta i seguenti dati personali:' />
      <ol className='mb-5'>
        <li>
          dati identificativi dell’utente (nome, cognome, codice fiscale,
          matricola, data di nascita);
        </li>
        <li>dati di contatto (e-mail);</li>
        <li>
          dati relativi alla categoria di appartenenza in Luiss (docente,
          collaboratore di cattedra, visiting professor, studente, ex-studente,
          etc..);
        </li>
        <li>dati relativi al percorso di studi;</li>
        <li>
          dati relativi alla conoscenza di lingue straniere (certificazioni);
        </li>
        <li>curriculum vitae;</li>
      </ol>

      <SectionTitle text='Per quali finalità raccogliamo i Suoi dati e perché il trattamento è legittimo?' />
      <Paragraph text='Il Titolare raccoglie e tratta le informazioni personali dell’interessato perseguendo le seguenti finalità:' />
      <ol className='mb-5'>
        <li>
          Creare e/o resettare il wallet dedicato ai digital assets
          dell’Università e messo a disposizione dell’utente (la legittimazione
          giuridica è rinvenibile nel contratto sottoscritto tra l’Università e
          l’utente);
        </li>
        <li>
          Erogare il servizio di autenticazione al fine di accedere alle Risorse
          richieste dall’utente (la legittimazione giuridica è rinvenibile nel
          contratto sottoscritto tra l’Università e l’utente);
        </li>
        <li>
          Creare ed inviare i digital assets sul wallet dell’utente (la
          legittimazione giuridica è rinvenibile nel contratto sottoscritto tra
          l’Università e l’utente);
        </li>
        <li>
          Gestire servizi connessi, quale verificare e monitorare il
          funzionamento del servizio e garantirne la sicurezza (la
          legittimazione giuridica è rinvenibile nel legittimo interesse
          dell’Università).
        </li>
      </ol>

      <SectionTitle text='Con quali modalità il Titolare tratta i Suoi dati personali e per quanto tempo li conserva?' />
      <Paragraph
        text='I dati personali saranno trattati con mezzi sia automatizzati che non automatizzati. I dati personali dell’interessato sono trattati esclusivamente in modalità elettronica (infrastruttura blockchain, server, database in cloud, software applicativi etc.).
        Il Titolare conserva le informazioni dell’interessato per un periodo di tempo individuato secondo i criteri di prescrizione civile e nel rispetto di specifiche leggi di settore, nonché in base ai termini necessari per il corretto perseguimento delle finalità sopra individuate.'
      />

      <SectionTitle text='A chi comunichiamo i Suoi dati personali?' />
      <p className='font-bold'>- ambito di comunicazione interno</p>
      <Paragraph text='Possono accedere ai dati personali dell’utente a cui è stato attribuito un wallet dedicato ai digital assets di Luiss solo i dipendenti e i collaboratori dell’Ateneo che ne abbiano necessità per offrire i servizi richiesti e limitatamente alle sole informazioni a ciò strumentali e connesse. In particolare:' />
      <ol className='mb-5'>
        <li>personale amministrativo;</li>
        <li>collaboratori</li>
      </ol>
      <Paragraph text='I nostri dipendenti e collaboratori sono stati informati e formati sull’importanza del rispetto dei principi e delle regole in materia di trattamento dei dati personali.' />
      <p className='font-bold'>- ambito di comunicazione esterno</p>
      <Paragraph text='Il Titolare condivide le informazioni personali degli interessati con alcuni fornitori che la coadiuvano nell’erogazione dei servizi richiesti e che sono a tal fine specificatamente nominati in qualità di Responsabili esterni del trattamento. In particolare:' />
      <ol className='mb-5'>
        <li>
          terze parti delle quali il Titolare si avvale per l’erogazione dei
          servizi strumentali alla gestione del complesso dei rapporti con
          l’interessato.
        </li>
        <Paragraph text='Qualora il fornitore acceda ai dati, lo farà rispettando la normativa vigente in materia di protezione dei dati personali e le istruzioni impartite dal Titolare.' />
        <li>
          nel caso in cui si necessario per motivi di sicurezza nazionale;
        </li>
        <li>per motivi di interesse generale;</li>
        <li>
          in relazione ad una richiesta proveniente dalle Autorità pubbliche.
        </li>
      </ol>
      <Paragraph text='Alcuni dati, immagazzinati direttamente sulla blockchain, potrebbero essere pubblicamente accessibili. Ciò significa che sarà possibile per qualsiasi terzo visualizzarli, attraverso dei blockchain explorer o direttamente sulla blockchain. Potrebbero essere pubblicamente accessibili i dati relativi all’indirizzo crittografico di un dato wallet dedicato ai digital assets di Luiss e gli indirizzi crittografici dei digital asset ad esso attribuiti. I dati degli utenti sono invece pseudonomizzati e non è immediatamente conoscibile l’attribuzione del singolo wallet ad una persona fisica / interessato.' />

      <SectionTitle text='I Suoi dati vengono trasferiti all’estero?' />
      <Paragraph text='I dati dell’iscritto non vengono trasferiti al di fuori dello Spazio Economico Europeo.' />

      <SectionTitle text='Quali sono i Suoi diritti come interessato del trattamento e come può esercitarli?' />
      <span>
        Il Regolamento europeo in materia di protezione dei dati personali
        (2016/679) garantisce all’interessato del trattamento specifici diritti.
        In particolare, il diritto di accesso, rettifica, opposizione a
        trattamenti finalizzati ad attività commerciali o esclusivamente
        automatizzati, cancellazione, limitazione e portabilità del dato, nonché
        il diritto di rivolgersi all’Autorità garante per la protezione dei dati
        personali. Qualora l’interessato desideri esercitare i diritti
        riconosciuti dalla legge potrà inviare, senza alcuna formalità, una
        e-mail all’indirizzo privacy@luiss.it o scrivere al Titolare, la Luiss
        Guido Carli, all’indirizzo di Viale Pola n. 12 – 00198 – Roma,
        specificando la propria richiesta e fornendo le informazioni necessarie
        all’identificazione dell’istante. I riferimenti del Responsabile per la
        protezione dei dati personali (RDP o Data Protection Officer, DPO) sono
        consultabili sul sito web del Titolare{' '}
        <a
          target='_blank'
          className='underline'
          href='http://www.luiss.it/contatti'
        >
          http://www.luiss.it/contatti.
        </a>{' '}
      </span>
    </>
  )
}

export default Cookies
