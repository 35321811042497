import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Card, CardCoomingSoon, PageContent, PageTitle } from '../components'
import Footer from '../components/Footer'
import { getNftsData } from '../firebase'
import { useUser } from '../hooks'
import useGetBalances from '../hooks/useGetBalance'
import { TNFT, TNFTsData } from '../types/nfts'

const Home = () => {
  const { t } = useTranslation('common')
  const navigate = useNavigate()

  const [nftsDB, setNFTsDB] = useState<TNFT[]>()

  const { user } = useUser()

  const fetchNfts = async () => {
    getNftsData().then((nfts) => {
      const data = nfts as TNFTsData

      // NOTE - This is just a temporary solution to redirect to the first NFT for the client demo
      if (data['1'] === undefined) {
        goToNFTDetails('0')
      }

      setNFTsDB(Object.values(data))
    })
  }

  const { balances, refetch } = useGetBalances(
    (user as any)?.aa_address,
    nftsDB || [],
  )

  const goToNFTDetails = (id: string) => {
    navigate(`/nft/${id}`)
  }

  useEffect(() => {
    fetchNfts()
  }, [])

  useEffect(() => {
    if (nftsDB && !!user?.aa_address) refetch()
  }, [user, nftsDB])

  return (
    <>
      <div className='flex h-full w-full flex-col space-y-8 lg:space-y-20'>
        <PageTitle
          title={t('sidebar.digital_collectibles')}
          subtitle={t('sidebar.digital_collectibles_submenu.nft')}
        />

        <PageContent>
          <div className='grid grid-cols-1 gap-4 min-[450px]:grid-cols-2 md:grid-cols-3 xl:grid-cols-4 2xl:grid-cols-5'>
            {balances.map((nft, index) => {
              if (!nft.balance) return null
              return (
                <Card
                  key={index}
                  nft={{
                    title: nft.title,
                    subtitle: nft.nftDescription,
                    image: nft.image,
                    balance: nft.balance,
                  }}
                  onClick={() => goToNFTDetails(index.toString())}
                />
              )
            })}
            {/* {Array.from({ length: 6 - balances.length }).map((_, i) => (
              <CardCoomingSoon key={i} />
            ))} */}
          </div>
        </PageContent>
        <Footer />
      </div>
    </>
  )
}

export default Home
