import React, {
  FC,
  LiHTMLAttributes,
  PropsWithChildren,
  useEffect,
  useState,
} from 'react'
import {
  faChevronDown,
  faChevronUp,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { cva, cx, VariantProps } from 'cva'

//TODO ask client how he wants menu design and refactor this component
const menuItem = cva(
  [
    'flex',
    'items-center',
    'px-3',
    'space-x-4',
    'w-full',
    'h-12',
    'text-blue-dark',
    'leading-4',
    'cursor-pointer',
    'rounded',
    'transition-all',
  ],
  {
    variants: {
      intent: {
        item: ['text-[1.25rem]', 'font-bold', 'border-gray', 'rounded-b-none'],
        subitem: ['font-bold', 'pl-6', 'hover:text-blue-600', 'text-normal'],
        bottomItem: ['font-normal', 'border-gray', 'rounded-t-none'],
      },
      status: {
        active: ['hover:text-[#1C64F2]'], //TODO put color in config
        inactive: ['fill-blue-dark'],
        disabled: [
          'text-gray',
          '!cursor-not-allowed',
          'hover:!bg-transparent',
          'hover:!text-gray',
        ],
      },
    },
    defaultVariants: {
      intent: 'item',
      status: 'inactive',
    },
  },
)

const menuIcon = cva([], {
  variants: {
    intent: {
      item: [''],
      subitem: [''],
    },
    status: {
      active: [''],
      inactive: [''],
      disabled: ['text-gray'],
    },
  },
  compoundVariants: [
    {
      intent: 'item',
      status: 'active',
      class: ['text-blue-dark'],
    },
    {
      intent: 'item',
      status: 'inactive',
      class: ['text-blue-dark'],
    },
    /* {
      intent: 'subitem',
      status: 'active',
      class: ['text-white'],
    }, */
    {
      intent: 'subitem',
      status: 'inactive',
      class: ['text-blue-dark'],
    },
  ],
  defaultVariants: {
    intent: 'item',
    status: 'inactive',
  },
})

type MenuItemProps = LiHTMLAttributes<HTMLLIElement> &
  VariantProps<typeof menuItem> & {
    icon: IconDefinition
    onClick?: () => void
    isDisabled?: boolean
    isActive?: boolean
    hasSubMenu?: boolean
    isSubMenuOpened?: boolean
    isSubMenuItem?: boolean
    isBottomItem?: boolean
  }

const MenuItem: FC<PropsWithChildren<MenuItemProps>> = ({
  children,
  className,
  icon,
  onClick,
  isDisabled,
  isActive,
  hasSubMenu,
  isSubMenuOpened,
  isSubMenuItem,
  isBottomItem,
}) => {
  const [active, setActive] = useState<boolean>(false)
  const [isHovered, setIsHovered] = useState<boolean>(false)

  useEffect(() => {
    if (isActive === undefined) return
    setActive(isActive)
  }, [isActive])

  return (
    <li
      className={cx(
        menuItem({
          intent: isSubMenuItem
            ? 'subitem'
            : isBottomItem
            ? 'bottomItem'
            : 'item',
          status: isDisabled ? 'disabled' : active ? 'active' : 'inactive',
        }),
        className,
      )}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
      onClick={() => !isDisabled && onClick && onClick()}
    >
      <FontAwesomeIcon
        icon={icon}
        className={cx(
          menuIcon({
            intent: isSubMenuItem ? 'subitem' : 'item',
            status: isDisabled ? 'disabled' : isHovered ? 'active' : 'inactive',
          }),
        )}
      />

      <span className='grow'>{children}</span>

      {hasSubMenu && (
        <>
          {isSubMenuOpened ? (
            <FontAwesomeIcon icon={faChevronUp} />
          ) : (
            <FontAwesomeIcon icon={faChevronDown} />
          )}
        </>
      )}
    </li>
  )
}

export default MenuItem
