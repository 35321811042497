import { doc, getDoc, serverTimestamp, setDoc } from 'firebase/firestore'

import { firestore } from '../firebase'
import { TUser } from '../types/user'

export async function updateUser(user: Partial<TUser>, uid: string) {
  try {
    await setDoc(doc(firestore, 'users', uid), {
      ...user,
      createdAt: user.createdAt || serverTimestamp(),
    })

    const updatedUser = await getUserByUid(uid)

    return updatedUser.data() as TUser
  } catch (err) {
   
    // TODO: Handle the error
  }
}

export async function getUserByUid(uid: string) {
  return getDoc(doc(firestore, 'users', uid))
}

export async function getDocFromFirestore(
  collection: string,
  docId: string,
): Promise<any> {
  const docRef = doc(firestore, collection, docId)
  const docSnap = await getDoc(docRef)

  if (docSnap.exists()) {
    return docSnap.data()
  }
}
