import React, { FC } from 'react'
import { faAngleLeft } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

import { Button } from '.'

type PageTitleProps = {
  title: string
  subtitle: string
  goBackTo?: string
  nftsLength?: number
}

const PageTitle: FC<PageTitleProps> = ({
  title,
  subtitle,
  goBackTo,
  nftsLength,
}) => {
  const navigate = useNavigate()

  const handleGoBack = () => {
    if (!goBackTo) return
    navigate(goBackTo)
  }
  const userHasMoreThanOneNft = nftsLength && nftsLength > 1

  return (
    <div className='flex w-full space-x-2 px-8 lg:px-10'>
      {goBackTo && userHasMoreThanOneNft && (
        <Button mode='icon' intent='ghost' onClick={handleGoBack}>
          <FontAwesomeIcon icon={faAngleLeft} />
        </Button>
      )}

      <div>
        <h1 className='w-fit border-b-[0.063rem] border-gray pb-2 text-xl font-bold text-blue-dark min-[390px]:pb-4 min-[390px]:text-[2rem]'>
          {title}
        </h1>

        <p className='pt-2 text-lg text-gray-dark'>{subtitle}</p>
      </div>
    </div>
  )
}

export default PageTitle
