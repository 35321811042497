import {
  DialogContent,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '@radix-ui/themes'

import { useMediaQuery } from '../hooks'
import Cookies from '../pages/Cookies'
import Button from './Button'

type CookiePoliciesType = {
  termsAccepted: {
    cookiePolicy: 'accepted' | 'declined' | ''
    privacyPolicy: boolean
  }
  setTermsAccepted: (termsAccepted: {
    cookiePolicy: 'accepted' | 'declined' | ''
    privacyPolicy: boolean
  }) => void
}

const CookiePolicies = ({
  setTermsAccepted,
  termsAccepted,
}: CookiePoliciesType) => {
  const isTablet = useMediaQuery('(max-width: 1024px)')
  const isMobile = useMediaQuery('(max-width: 640px)')
  const parsedTermsAccepted = JSON.parse(
    localStorage.getItem('termsAccepted')!,
  ) ?? {
    cookiePolicy: '',
    privacyPolicy: false,
  }

  const cookies = parsedTermsAccepted?.cookiePolicy || ''

  return (
    <DialogRoot open={cookies === ''}>
      <DialogContent
        onPointerDownOutside={(e) => e.preventDefault()}
        style={{ maxWidth: '90%', borderRadius: '0' }}
        className={`overflow-hidden text-left ${
          isTablet ? 'inset-0' : 'bottom-10'
        } border-blue-darkp-8 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 fixed z-50 m-auto flex ${
          isMobile ? 'h-4/5' : 'h-2/5'
        } flex-col items-center border border-[#2A3978] px-8 py-5 shadow-lg md:flex-col`}
      >
        <DialogTitle className='w-full pb-6 text-xl font-semibold text-blue-dark'>
          Cookie Policies
        </DialogTitle>
        <div className='overflow-y-auto scrollbar-hide'>
          <Cookies isPopup />
        </div>
        <div
          className={`flex w-auto ${
            isTablet ? 'space-y-1' : 'space-x-2'
          } flex-col items-center  ${
            isMobile ? 'pb-0' : 'pb-6'
          } pt-3 md:flex-row`}
        >
          <DialogTrigger>
            <Button intent='primary'>
              <div
                onClick={() => {
                  setTermsAccepted({
                    ...termsAccepted,
                    cookiePolicy: 'accepted',
                  })
                  localStorage.setItem(
                    'termsAccepted',
                    JSON.stringify({
                      ...termsAccepted,
                      cookiePolicy: 'accepted',
                    }),
                  )
                }}
                className='flex items-center justify-center space-x-2 text-[1rem]'
              >
                <span className='font-primary-bold'>Accept</span>
              </div>
            </Button>
          </DialogTrigger>
          <DialogTrigger>
            <Button
              onClick={() => {
                setTermsAccepted({ ...termsAccepted, cookiePolicy: 'declined' })
                localStorage.setItem(
                  'termsAccepted',
                  JSON.stringify({
                    ...termsAccepted,
                    cookiePolicy: 'declined',
                  }),
                )
              }}
              intent='ghost'
            >
              <div className='flex items-center justify-center space-x-2 text-[1rem]'>
                <span className='font-primary-bold'>Decline</span>
              </div>
            </Button>
          </DialogTrigger>
        </div>
      </DialogContent>
    </DialogRoot>
  )
}

export default CookiePolicies
