import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate, useParams } from 'react-router-dom'

import { CardCoomingSoon, PageContent, PageTitle } from '../../components'
import Footer from '../../components/Footer'
import { getNftsData } from '../../firebase'
import { useMediaQuery } from '../../hooks'
import { TNFT, TNFTsData } from '../../types/nfts'

const NFTDetail = () => {
  const { t } = useTranslation('common')
  const { id } = useParams()
  const navigate = useNavigate()

  const [nft, setNFT] = useState<TNFT>()
  const [nftsLength, setNFTsLength] = useState<number>(0)
  useEffect(() => {
    if (!id) navigate('/404')

    getNftsData().then((nfts) => {
      const data = nfts as TNFTsData
      setNFTsLength(Object.keys(data).length)
      setNFT(data[Number(id) || 0])
    })
  }, [])

  const isTablet = useMediaQuery('(max-width: 1024px)')

  return (
    <div className='flex w-full flex-col space-y-8 lg:space-y-10 xl:h-full'>
      <div
        className={`container mx-auto max-w-6xl ${!isTablet ? 'h-full' : ''}`}
      >
        <div className='h-24'>
          <PageTitle
            title={t('sidebar.digital_collectibles')}
            subtitle={t('sidebar.digital_collectibles_submenu.nft')}
            goBackTo='/'
            nftsLength={nftsLength}
          />
        </div>

        <PageContent>
          <>
            <div
              className={`mt-8 grid ${
                isTablet ? 'h-full' : ''
              } grid-cols-1 items-center xl:grid-cols-2`}
            >
              <img
                className='border-[20px] border-green drop-shadow-lg'
                src={nft?.image}
                alt={nft?.title}
              />
              <div className=''>
                <p className='mt-5 bg-green px-6 py-5 text-3xl uppercase text-white xl:mt-0'>
                  {nft?.title.toUpperCase()}
                </p>
                <div className='px-6 py-5'>
                  <div className='mb-5'>
                    <p className='mb-2 text-xl text-black'>Collection name</p>
                    <p className='text-sm text-gray-dark'>
                      {nft?.collectionDescription || ''}
                    </p>
                  </div>
                  <div className=''>
                    <p className='mb-2 text-xl text-black'>Description</p>
                    <p className='text-sm text-gray-dark'>
                      {nft?.nftDescription || ''}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            {/* <div className='my-10 mb-8 grid grid-cols-2 gap-4 px-4 md:grid-cols-3 md:px-8 xl:grid-cols-4 xl:px-0 2xl:grid-cols-5'>
                           {Array.from({ length: 8 }).map((_, i) => (
                <CardCoomingSoon key={i} />
              ))}
            </div> */}
          </>
        </PageContent>
      </div>
      <Footer />
    </div>
  )
}

export default NFTDetail
