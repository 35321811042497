import React from 'react'
import { faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
} from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import { useNavigate, useOutletContext } from 'react-router-dom'
import { toast } from 'react-toastify'

import { Button, Spinner } from '../components'
import CookiePolicies from '../components/CookiePolicies'
import TermsAndConditions from '../components/TermsAndConditions'
import { auth, provider } from '../firebase'
import { useUser } from '../hooks'
import { getUserByUid } from '../utils/firebase'
import {
  getUserBalance,
  handleUserAccountCreation,
} from '../utils/userAccountCreation'

const Welcome: React.FC = () => {
  const { t } = useTranslation('welcome')
  //eslint-disable-next-line @typescript-eslint/ban-ts-comment
  /* @ts-ignore */
  const [termsAccepted, setTermsAccepted] =
    useOutletContext() /* TODO fix type */

  const navigate = useNavigate()

  const { createUser } = useUser()
  const [isLoading, setIsLoading] = React.useState(false)

  const ssoLogin = () => {
    try {
      signInWithPopup(auth, provider)
        .then(async (data) => {
          setIsLoading(true)
          const bearerToken = await data.user.getIdToken()
          const currentUser = (await getUserByUid(data.user.uid)).data()

          if (!currentUser) {
            const isUserCreatedCorrectly = await handleUserAccountCreation(
              data.user.email || '',
              data.user.uid,
              bearerToken,
            )

            const currentUser = (await getUserByUid(data.user.uid)).data()

            createUser({
              uid: data.user.uid,
              email: data.user.email || undefined,
              aa_address: currentUser?.aa_address || undefined,
            })

            if (isUserCreatedCorrectly) {
              toast.success(t('status.success'))
              navigate('/')
            } else {
              toast.warning(t('status.failed'))
              navigate('/')
            }
          } else {
            await getUserBalance(
              currentUser.aa_address,
              data.user.uid,
              bearerToken,
            )
            createUser({
              uid: data.user.uid,
              email: data.user.email || undefined,
              aa_address: currentUser?.aa_address || undefined,
            })

            if (currentUser && currentUser.status === 'SUCCESS') {
              toast.success(t('tx_status.success'))
              navigate('/')
              return
            }

            if (currentUser && currentUser.status === 'FAILED') {
              toast.error(t('tx_status.failed'))
              const isUserCreatedCorrectly = await handleUserAccountCreation(
                data.user.email || '',
                data.user.uid,
                bearerToken,
              )
              if (isUserCreatedCorrectly) {
                toast.success(t('status.success'))
                navigate('/')
              } else {
                toast.error(t('status.failed'))
                navigate('/')
              }
            }
          }
          setIsLoading(false)
        })
        .catch(() => {
          toast.error(t('generic_error'))
          setIsLoading(false)
        })
    } catch (error) {
      toast.error(t('generic_error'))
      setIsLoading(false)
    }
  }

  return (
    <>
      {isLoading ? (
        <div className='fixed inset-0 z-40 flex h-full w-full items-center justify-center bg-black bg-opacity-60'>
          <Spinner />
        </div>
      ) : null}
      <div id='welcome'>
        <h1
          id='welcome-title'
          className='pb-5 text-5xl font-bold text-blue-dark'
        >
          {t('welcome')}
        </h1>
        <h4 id='welcome-subtitle' className='pb-5 text-2xl text-blue-dark'>
          {t('subtitle')}
        </h4>

        <div className='space-y-3 lg:space-y-4'>
          <p className='text-blue-dark'>{t('p1')}</p>
          <p className='text-blue-dark'>{t('p2')}</p>
          <p className='text-blue-dark'>{t('p3')}</p>
        </div>

        <div
          id='enter-btn-div'
          className='mt-12 flex items-center justify-start'
        >
          <Button intent={'primary'} onClick={() => ssoLogin()}>
            <div className='flex items-center justify-center space-x-2 text-[1rem]'>
              <span className='font-primary-bold'>{t('enterCta')}</span>
              <span>
                <FontAwesomeIcon icon={faChevronRight} className='w-5' />
              </span>
            </div>
          </Button>
        </div>
        <p
          id='privacy-link'
          className='mt-5 flex text-xs font-bold italic text-blue-dark'
        >
          {t('terms')}
          <a href='/legals/privacy' className='ml-1 underline'>
            {t('policies')}
          </a>
        </p>

        <CookiePolicies
          termsAccepted={termsAccepted}
          setTermsAccepted={setTermsAccepted}
        />

        <TermsAndConditions
          setTermsAccepted={setTermsAccepted}
          termsAccepted={termsAccepted}
        />
      </div>
    </>
  )
}

export default Welcome
