import { initializeApp } from 'firebase/app'
import { SAMLAuthProvider, getAuth } from 'firebase/auth'
import { doc, getDoc, getFirestore } from 'firebase/firestore'

const firebase = initializeApp({
  apiKey: process.env[`REACT_APP_FIREBASE_API_KEY`],
  authDomain: process.env[`REACT_APP_FIREBASE_AUTH_DOMAIN`],
  projectId: process.env[`REACT_APP_FIREBASE_PROJECT_ID`],
  storageBucket: process.env[`REACT_APP_FIREBASE_STORAGE_BUCKET`],
  messagingSenderId: process.env[`FIREBASE_MESSAGING_SENDER_ID`],
  appId: process.env[`REACT_APP_FIREBASE_APP_ID`],
})

export const firestore = getFirestore(firebase)
export const auth = getAuth(firebase)
export const provider = new SAMLAuthProvider('saml.luiss-adsf')

export const getNftsData = async () => {
  const docRef = doc(firestore, 'nfts', 'info')

  const docSnap = await getDoc(docRef)

  return docSnap.data()
}

