import React from 'react'
import { faCheck } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import { useMediaQuery } from '../hooks'

interface CheckboxProps {
  label: string
  isChecked: boolean
  onChange: (isChecked: boolean) => void
}

const CustomCheckbox: React.FC<CheckboxProps> = ({
  label,
  isChecked,
  onChange,
}) => {
  const toggleCheckbox = () => {
    const newCheckedState = !isChecked
    onChange(newCheckedState)
  }
  const isTablet = useMediaQuery('(max-width: 768px)')
  const labelClassnameTablet =
    'flex items-center justify-between w-[80%] m-auto'
  return (
    <label
      className={
        isTablet ? labelClassnameTablet : 'inline-flex items-center space-x-3'
      }
    >
      <span className='text-gray-700 w-fit text-xs font-bold'>{label}</span>
      <div
        className={`border-blue-500 h-5 w-5 border border-lighter-blue transition duration-300 ${
          isChecked ? 'bg-lighter-blue' : ' bg-white'
        } flex cursor-pointer items-center justify-center`}
        onClick={toggleCheckbox}
      >
        {isChecked && (
          <div className='text-[8px]'>
            <FontAwesomeIcon icon={faCheck} size='xs' />
          </div>
        )}
      </div>
    </label>
  )
}

export default CustomCheckbox
