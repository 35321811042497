import { useEffect, useState } from 'react'
import { polygon } from 'viem/chains'
import { useContractReads } from 'wagmi'

import erc721Abi from '../abi/ERC721'
import erc1155Abi from '../abi/ERC1155'
import { TBalanceNFT, TNFT } from '../types/nfts'

const useGetBalances = (address: string, nfts: TNFT[]) => {
  const [balances, setBalances] = useState<TBalanceNFT[]>([])
  const [contracts, setContracts] = useState<any[]>([])

  // Creates the list of contracts to read
  // TODO Maximum update depth exceeded console.error();
  useEffect(() => {
    const featContracts = nfts.map((nft) => {
      return {
        address: nft.address,
        abi: nft.type === 'ERC721' ? erc721Abi : erc1155Abi,
        functionName: 'balanceOf',
        chainId: polygon.id,
        args: nft.type === 'ERC721' ? [address] : [address, nft.id],
      }
    })

    setContracts(featContracts)
  }, [address, nfts])

  const { refetch } = useContractReads({
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    contracts,
    onSuccess: (data) => {
      // Reset total achievements
      const balanceNfts = nfts.map((nft, index) => {
        return {
          ...nft,
          balance: Number(data[index].result as bigint),
        }
      })
      setBalances(balanceNfts)
    },
  })

  return { balances, refetch }
}

export default useGetBalances
