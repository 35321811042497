import { create } from 'zustand'

export type AppState = {
  sidebarOpen: boolean
  setSidebarOpen: (sidebarOpen: boolean) => void
}

export const useAppStore = create<AppState>((set) => ({
  sidebarOpen: false,
  setSidebarOpen: (sidebarOpen) => set({ sidebarOpen }),
}))
