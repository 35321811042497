import React from 'react'

const ColorBand: React.FC = () => {
  return (
    <div className='flex w-full'>
      <div className='flex h-1 w-full bg-green' />
      <div className='flex h-1 w-full bg-orange' />
      <div className='flex h-1 w-full bg-light-blue' />
      <div className='flex h-1 w-full bg-pink' />
      <div className='flex h-1 w-full bg-orange' />
    </div>
  )
}

export default ColorBand
