import React from 'react'
import { useTranslation } from 'react-i18next'

import { useMediaQuery } from '../hooks'
import ColorBand from './ColorBand'

const Footer: React.FC = () => {
  const { t } = useTranslation('common')
  const isMobile = useMediaQuery('(max-width: 640px)')

  return (
    <div>
      <ColorBand />
      <footer className={`bg-blue-dark px-6 py-8`}>
        <div className='flex flex-col items-center justify-center'>
          <div
            className={`${
              isMobile ? 'mb-2' : ''
            } flex items-center justify-center`}
          >
            <p className='text-center text-sm font-normal text-white'>
              © 2023 Luiss Guido Carli - Viale Pola 12, 00198 Roma, Italia -
              P.IVA 01067231009
            </p>
          </div>
          <div
            className={`flex ${
              isMobile ? 'flex-col' : ''
            } items-center justify-end space-x-2`}
          >
            <a className='text-sm text-white' href='/legals/terms-of-service'>
              {t('tos')}
            </a>
            {!isMobile && <span className='text-[1rem] text-white'>•</span>}
            <a className='text-sm text-white' href='/legals/privacy'>
              {t('privacy')}
            </a>
            {!isMobile && <span className='text-[1rem] text-white'>•</span>}
            <a className='text-sm text-white' href='/legals/cookies'>
              {t('cookies')}
            </a>
          </div>
        </div>
      </footer>
    </div>
  )
}

export default Footer
