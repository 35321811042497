import React, { useEffect } from 'react'
import { onAuthStateChanged } from 'firebase/auth'
import { AnimatePresence, motion } from 'framer-motion'
import { Outlet, useNavigate } from 'react-router-dom'

import { Header, Sidebar } from '.'
import { auth } from '../firebase'
import { useMediaQuery, useToggleSidebar } from '../hooks'

//NOTE - This Layout component wraps the user pages
const LayoutGrid = () => {
  const isTablet = useMediaQuery('(max-width: 1024px)')
  const { sidebarOpen, setSidebarOpen } = useToggleSidebar()
  const navigate = useNavigate()

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (!user) {
        navigate('/auth')
      }
    })

    return () => unsubscribe()
  }, [])

  return (
    <div className='h-screen w-full overflow-x-hidden lg:flex'>
      {isTablet ? (
        <Header onShowSidebar={() => setSidebarOpen(!sidebarOpen)} />
      ) : (
        <Sidebar />
      )}

      {isTablet && (
        <AnimatePresence>
          {sidebarOpen && (
            <motion.div
              className='absolute right-0 top-0 z-50 h-screen w-full overflow-x-hidden lg:w-fit'
              initial={{ right: '-100%' }}
              animate={{ right: 0 }}
              exit={{ right: '-100%' }}
              transition={{ type: 'tween', duration: 0.3 }}
            >
              <Sidebar />
            </motion.div>
          )}
        </AnimatePresence>
      )}
      {/* tolto overflow y hidden */}
      <main className='min-h-fit w-full overflow-x-hidden pt-8 lg:pt-10'>
        <Outlet />
      </main>
    </div>
  )
}

export default LayoutGrid
