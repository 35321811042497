import React from 'react'
import { RouterProvider } from 'react-router-dom'

import './utils/i18n'

import { ToastContainer } from 'react-toastify'
import { configureChains, createConfig, WagmiConfig } from 'wagmi'
import { polygon } from 'wagmi/chains'
import { jsonRpcProvider } from 'wagmi/providers/jsonRpc'

import '@radix-ui/themes/styles.css'
import 'react-toastify/dist/ReactToastify.css'

import { Theme } from '@radix-ui/themes'

import routes from './utils/routes'

const App = () => {
  const PolygonPublicNode =
    process.env.REACT_APP_RPC_ENDPOINT || 'https://rpc-mumbai.maticvigil.com'

  const { publicClient, webSocketPublicClient } = configureChains(
    [polygon],
    [
      jsonRpcProvider({
        rpc: () => {
          return {
            http: PolygonPublicNode,
          }
        },
      }),
    ],
  )

  const config = createConfig({
    autoConnect: true,
    publicClient,
    webSocketPublicClient,
  })

  return (
    <React.StrictMode>
      <WagmiConfig config={config}>
        <ToastContainer
          position='top-center'
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme='dark'
          style={{ zIndex: '100' }}
        />
        <Theme>
          <RouterProvider router={routes} />
        </Theme>
      </WagmiConfig>
    </React.StrictMode>
  )
}

export default App
