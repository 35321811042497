import { useAppStore } from '../context'

const useToggleSidebar = () => {
  const { sidebarOpen, setSidebarOpen } = useAppStore(
    ({ sidebarOpen, setSidebarOpen }) => ({
      sidebarOpen,
      setSidebarOpen,
    }),
  )

  return {
    sidebarOpen,
    setSidebarOpen,
  }
}

export default useToggleSidebar
