import { create } from 'zustand'
import { createJSONStorage, persist } from 'zustand/middleware'

import { TUser } from '../types/user'

interface IZustandUser {
  user: Partial<TUser>
  createUser: (user: Partial<TUser>) => void
  updateUser: (updatedUser: Partial<TUser>) => void
}

const useUser = create<IZustandUser>()(
  persist(
    (set) => ({
      user: {},
      createUser: (user: Partial<TUser>) => set(() => ({ user })),
      updateUser: (updatedUser: Partial<TUser>) =>
        set((state: IZustandUser) => ({
          user: { ...state.user, ...updatedUser },
        })),
    }),
    {
      name: 'user',
      storage: createJSONStorage(() => localStorage),
    },
  ),
)

export default useUser
