import React, { FC } from 'react'
import { faBars } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate } from 'react-router-dom'

import { Button } from '.'

type HeaderProps = {
  onShowSidebar?: () => void
}
export const headerHeight = '6.63rem'
const Header: FC<HeaderProps> = ({ onShowSidebar }) => {
  const navigate = useNavigate()
  const goToHomepage = () => navigate('/')
  return (
    <header
      className={`flex w-full items-center justify-between bg-blue-dark px-10 py-5`}
    >
      <div
        className='flex cursor-pointer items-center justify-start space-x-5'
        onClick={goToHomepage}
      >
        <img src='/assets/images/logo-white.png' className='h-14' />
      </div>

      {onShowSidebar && (
        <Button mode='icon' intent='ghost-contrary' onClick={onShowSidebar}>
          <FontAwesomeIcon icon={faBars} />
        </Button>
      )}
    </header>
  )
}

export default Header
