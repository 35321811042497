import React, { ButtonHTMLAttributes, FC, PropsWithChildren } from 'react'
import { cva, cx, VariantProps } from 'cva'

const button = cva(
  [
    'py-4',
    'px-8',
    'transition-all',
    'space-x-4',
    'flex',
    'items-center',
    'justify-center',
    'w-fit',
  ],
  {
    variants: {
      mode: {
        text: [''],
        icon: ['h-9', 'w-9', '!p-1'],
      },
      intent: {
        primary: ['text-white', 'bg-blue-dark', 'hover:bg-blue-mid'],
        outlined: [
          'text-black',
          'bg-white',
          'border',
          'border-gray',
          'hover:bg-blue-dark',
          'hover:text-white',
        ],
        fullPrimary: [
          'text-white',
          'bg-blue-dark',
          'hover:bg-blue-mid',
          '!w-full',
        ],
        fullOutlined: [
          'text-black',
          'bg-white',
          'border',
          'border-gray',
          'hover:bg-blue-dark',
          'hover:text-white',
          '!w-full',
        ],
        fullGhost: ['text-black', 'hover:text-blue-dark', '!w-full'],
        ghost: ['text-blue-dark', 'hover:bg-white', 'hover:text-blue-dark'],
        'ghost-contrary': [
          'text-white',
          'hover:text-blue-dark',
          'hover:bg-white',
        ],
        disabled: [
          'text-gray-dark',
          'bg-gray-light',
          'cursor-not-allowed',
          'bg-gray',
        ],
      },
    },
    defaultVariants: {
      mode: 'text',
      intent: 'primary',
    },
  },
)

type ButtonProps = ButtonHTMLAttributes<HTMLButtonElement> &
  VariantProps<typeof button>

const Button: FC<PropsWithChildren<ButtonProps>> = ({
  mode,
  intent,
  className,
  children,
  ...props
}) => (
  <button className={cx(button({ mode, intent }), className)} {...props}>
    {mode === 'icon'
      ? React.cloneElement(children as React.ReactElement, {
          className: 'h-full w-[40px]',
        })
      : children}
  </button>
)

export default Button
