import React, { FC, PropsWithChildren } from 'react'

const PageContent: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className='h-full w-full overflow-y-hidden px-8 pb-40 lg:px-10 lg:pb-10'>
      {children}
    </div>
  )
}

export default PageContent
