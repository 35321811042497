import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'

// Translation files
import commonEN from '../locales/en/common.json'
import cookiesEN from '../locales/en/cookies.json'
import signInEN from '../locales/en/signIn.json'
import signUpEN from '../locales/en/signUp.json'
import welcomeEN from '../locales/en/welcome.json'
import commonIT from '../locales/it/common.json'
import cookiesIT from '../locales/it/cookies.json'
import signInIT from '../locales/it/signIn.json'
import signUpIT from '../locales/it/signUp.json'
import welcomeIT from '../locales/it/welcome.json'

const resources = {
  en: {
    common: commonEN,
    signIn: signInEN,
    signUp: signUpEN,
    welcome: welcomeEN,
    cookies: cookiesEN,
  },
  it: {
    common: commonIT,
    signIn: signInIT,
    signUp: signUpIT,
    welcome: welcomeIT,
    cookies: cookiesIT,
  },
}

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources,
    lng: 'en', // if you're using a language detector, do not define the lng option
    fallbackLng: 'en',

    interpolation: {
      escapeValue: false, // react already safes from xss => https://www.i18next.com/translation-function/interpolation#unescape
    },
  })
