import { useTranslation } from 'react-i18next'

const Cookies = ({ isPopup = false }: { isPopup?: boolean }) => {
  const { t } = useTranslation('cookies')

  const SectionTitle = ({ text }: { text: string }) => {
    const classname = isPopup
      ? 'w-full pb-2 font-semibold text-blue-dark text-sm'
      : 'mb-2 text-2xl'
    return <h4 className={classname}>{text}</h4>
  }

  const Paragraph = ({ text }: { text: string }) => {
    const classname = isPopup
      ? 'mb-4 text-xs font-normal leading-relaxed text-gray-dark'
      : 'mb-5'
    return <p className={classname}>{text}</p>
  }

  const CookieTable = () => (
    <table className={`${isPopup && 'text-xs'} mb-5 border text-center`}>
      <thead className='border'>
        <tr className='border'>
          <th className='border'>{t('cookie_name')}</th>
          <th className='border'>{t('cookie_type')}</th>
          <th className='border'>{t('duration')}</th>
          <th className='border'>{t('purpose')}</th>
          <th className='border'>{t('consent')}</th>
        </tr>
      </thead>
      <tbody className='border'>
        <tr className='border'>
          <td className='border'>Wagmi store // wagmi</td>
          <td className='border'>{t('technical')}</td>
          <td className='border'>{t('persistent')}</td>
          <td className='border'>{t('purpose_1')}</td>
          <td className='border'>{t('not_requested')}</td>
        </tr>
        <tr className='border'>
          <td className='border'>wagmi cache // Wagmi</td>
          <td className='border'>{t('technical')}</td>
          <td className='border'>{t('persistent')}</td>
          <td className='border'>{t('purpose_2')}</td>
          <td className='border'>{t('not_requested')}</td>
        </tr>
      </tbody>
    </table>
  )
  const classNameForPandSpanTags = isPopup
    ? 'mb-4 text-xs font-normal leading-relaxed text-gray-dark'
    : 'mb-5'

  return (
    <>
      {!isPopup && <h1 className='mb-5 text-5xl font-bold'>{t('title')}</h1>}
      <SectionTitle text={t('subtitle')} />
      <Paragraph text={t('intro1')} />
      <div
        className={
          isPopup
            ? 'mb-4 text-xs font-normal leading-relaxed text-gray-dark'
            : 'mb-5'
        }
      >
        <span> {t('intro2')} </span>
        <a
          target='_blank'
          className='underline underline-offset-2'
          href='https://www.luiss.it/informativa-sulla-privacy '
        >
          https://www.luiss.it/informativa-sulla-privacy{' )'}
        </a>
      </div>
      <ol className='list-decimal'>
        <li>
          <SectionTitle text={t('what_are_cookies')} />
          <Paragraph text={t('what_are_cookies_text')} />
        </li>
        <li>
          <SectionTitle text={t('types_of_cookies')} />
          <Paragraph text={t('types_of_cookies_text')} />
          <Paragraph text={t('types_of_cookies_text_2')} />
          <ol className='list-[lower-alpha]'>
            <li>
              <SectionTitle text={t('types_of_cookies_technical')} />
              <Paragraph text={t('types_of_cookies_technical_text')} />
              <ol className='list-disc'>
                <li>
                  <SectionTitle text={t('necessary_cookies')} />
                  <Paragraph text={t('necessary_cookies_text')} />
                </li>
                <li>
                  <SectionTitle text={t('performace_cookies')} />
                  <Paragraph text={t('performace_cookies_text')} />
                </li>
                <li>
                  <SectionTitle text={t('preferences_cookies')} />
                  <Paragraph text={t('preferences_cookies_text')} />
                </li>
              </ol>
            </li>
            <li>
              <SectionTitle text={t('types_of_cookies_profiling')} />
              <Paragraph text={t('types_of_cookies_profiling_1')} />
              <Paragraph text={t('types_of_cookies_profiling_2')} />
              <Paragraph text={t('types_of_cookies_profiling_3')} />
            </li>
          </ol>
        </li>
        <li>
          <SectionTitle text={t('processed_cookies')} />
          <Paragraph text={t('processed_cookies_table_title')} />
          <CookieTable />
        </li>
        <li>
          <SectionTitle text={t('fourth_paragraph_title')} />
          <span className={classNameForPandSpanTags}>
            {t('fourth_paragraph_1')}
            <a
              className='underline underline-offset-2'
              href='https://collectibles.luiss.it/legals/cookies'
            >
              https://collectibles.luiss.it/legals/cookies
            </a>
          </span>
          <Paragraph text={t('fourth_paragraph_1a')} />
          <span className={classNameForPandSpanTags}>
            {' '}
            <a
              target='_blank'
              className='underline underline-offset-2'
              href='http://www.youronlinechoices.com'
            >
              http://www.youronlinechoices.com
            </a>
          </span>
          <Paragraph text={t('fourth_paragraph_2')} />
          <div className='my-5'>
            <p className={classNameForPandSpanTags}>
              Google Chrome:{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='https://support.google.com/chrome/bin/answer.py?hl=it&answer=95647&p=cpn_cookies'
              >
                https://support.google.com/chrome/bin/answer.py?hl=it&answer=95647&p=cpn_cookies
              </a>
            </p>
            <p className={classNameForPandSpanTags}>
              Mozilla Firefox:{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='http://support.mozilla.org/it-IT/kb/Enabling%20and%20disabling%20cookies'
              >
                http://support.mozilla.org/it-IT/kb/Enabling%20and%20disabling%20cookies
              </a>
            </p>
            <p className={classNameForPandSpanTags}>
              Apple Safari:{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='http://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT'
              >
                http://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT
              </a>
            </p>
            <p className={classNameForPandSpanTags}>
              Opera:{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='http://help.opera.com/Windows/10.00/it/cookies.html'
              >
                http://help.opera.com/Windows/10.00/it/cookies.html
              </a>
            </p>
            <p className={classNameForPandSpanTags}>
              Microsoft Edge{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09'
              >
                https://support.microsoft.com/it-it/microsoft-edge/eliminare-i-cookie-in-microsoft-edge-63947406-40ac-c3b8-57b9-2a946a29ae09
              </a>
            </p>
            <p className={classNameForPandSpanTags}>
              Internet Explorer{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11'
              >
                http://windows.microsoft.com/it-it/internet-explorer/delete-manage-cookies#ie=ie-11
              </a>
            </p>
            <p className={classNameForPandSpanTags}>
              Mavericks{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='https://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT'
              >
                https://support.apple.com/kb/PH17191?viewlocale=it_IT&locale=it_IT
              </a>
            </p>
            <p className={classNameForPandSpanTags}>
              Yosemite{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=it_IT'
              >
                https://support.apple.com/kb/PH19214?viewlocale=it_IT&locale=it_IT
              </a>
            </p>
          </div>
        </li>
        <li>
          <SectionTitle text={t('fifth_paragraph_title')} />
          <Paragraph text={t('fifth_paragraph_text_1')} />
          <span>
            <span
              className={
                isPopup
                  ? 'mb-4 text-xs font-normal leading-relaxed text-gray-dark'
                  : 'mb-5'
              }
            >
              {t('fifth_paragraph_text_2')}{' '}
              <a
                target='_blank'
                className='underline underline-offset-2'
                href='https://www.luiss.it/informativa-sulla-privacy'
              >
                https://www.luiss.it/informativa-sulla-privacy
              </a>
            </span>
          </span>
        </li>
      </ol>
    </>
  )
}

export default Cookies
