import { createBrowserRouter } from 'react-router-dom'

import { LayoutCentered, LayoutGrid } from '../components'
import { Home, NFTDetail, Welcome } from '../pages'
import Cookies from '../pages/Cookies'
import Privacy from '../pages/Privacy'
import ToS from '../pages/ToS'

const routes = createBrowserRouter([
  {
    path: '/auth',
    element: <LayoutCentered redirect />,
    children: [
      {
        path: '/auth/',
        element: <Welcome />,
      },
    ],
  },
  {
    path: '/legals',
    element: <LayoutCentered showCarousel={false} />,
    children: [
      {
        path: '/legals/privacy',
        element: (
          <div className='mx-auto overflow-y-auto pb-24 pt-[4.25rem] text-blue-dark md:max-w-lg lg:max-w-3xl'>
            <Privacy />
          </div>
        ),
      },
      {
        path: '/legals/terms-of-service',
        element: (
          <div className='mx-auto overflow-y-auto pb-24 pt-[4.25rem] text-blue-dark md:max-w-lg lg:max-w-3xl'>
            <ToS />
          </div>
        ),
      },
      {
        path: '/legals/cookies',
        element: (
          <div className='overflow-y-auto pb-24 pt-[4.25rem] text-blue-dark sm:mx-auto md:max-w-lg lg:max-w-3xl'>
            <Cookies />
          </div>
        ),
      },
    ],
  },
  {
    path: '/',
    element: <LayoutGrid />,
    children: [
      {
        path: '/',
        element: <Home />,
      },
      {
        path: '/nft/:id',
        element: <NFTDetail />,
      },
    ],
  },
])

export default routes
