import React, { FC, InputHTMLAttributes } from 'react'
import { cva, cx, VariantProps } from 'cva'

const input = cva(
  [
    'h-9',
    'w-full',
    'rounded',
    'px-2',
    'ring-1',
    'text-sm',
    'placeholder:text-sm',
    'transition-all',
    'outline-none',
    'focus:ring-blue-mid',
    'focus:shadow-centered',
    'focus:shadow-blue-mid',
  ],
  {
    variants: {
      intent: {
        primary: ['ring-gray', 'hover:ring-blue-mid'],
        error: ['ring-red', 'hover:ring-red'],
        // disabled: ['bg-gray', 'text-gray', 'ring-0'],
      },
    },
    defaultVariants: {
      intent: 'primary',
    },
  },
)

type InputProps = InputHTMLAttributes<HTMLInputElement> &
  VariantProps<typeof input>

const Input: FC<InputProps> = ({ intent, className, ...props }) => (
  <input className={cx(input({ intent }), className)} {...props} />
)

export default Input
