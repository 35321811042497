import React, { useEffect, useState } from 'react'
import { Auth, onAuthStateChanged } from 'firebase/auth'
import { Carousel } from 'react-responsive-carousel'

import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader

import { Outlet, useLocation, useNavigate } from 'react-router-dom'

import { auth } from '../firebase'
import { useMediaQuery } from '../hooks'
import { getUserByUid } from '../utils/firebase'
import { Header } from './'
import Footer from './Footer'

interface TLayoutCentered {
  showCarousel?: boolean
  redirect?: boolean
}

const LayoutCentered: React.FC<TLayoutCentered> = ({
  showCarousel = true,
  redirect = false,
}) => {
  const navigate = useNavigate()
  const isTablet = useMediaQuery('(max-width: 1300px)')
  const checkIfUserIsLoggedInAndHasWallet = async (auth: Auth) => {
    if (auth.currentUser?.uid) {
      const currUser = await getUserByUid(auth.currentUser?.uid)
      if (currUser.data()?.status === 'SUCCESS' && redirect) {
        navigate('/')
      }
    }
  }

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        checkIfUserIsLoggedInAndHasWallet(auth)
      }
    })

    return () => unsubscribe()
  }, [])

  const slides = [
    '/assets/images/carousel/slide-01.webp',
    '/assets/images/carousel/slide-02.webp',
    '/assets/images/carousel/slide-03.webp',
    '/assets/images/carousel/slide-04.webp',
  ]
  const isMobile = useMediaQuery('(max-width: 620px)')

  type TermsAcceptedType = {
    cookiePolicy: 'accepted' | 'declined' | ''
    privacyPolicy: boolean
  }
  const parsedTermsAccepted = JSON.parse(
    localStorage.getItem('termsAccepted')!,
  ) ?? {
    cookiePolicy: '',
    privacyPolicy: false,
  }
  const [termsAccepted, setTermsAccepted] = useState<TermsAcceptedType>({
    cookiePolicy: parsedTermsAccepted?.cookiePolicy || '',
    privacyPolicy: parsedTermsAccepted?.privacyPolicy || false,
  })

  const location = useLocation()
  const isLegalsPage = location.pathname.includes('/legals')
  return (
    <div
      className={`relative flex flex-col justify-between ${
        isLegalsPage ? '' : 'h-screen'
      }`}
    >
      <Header />

      <div
        className={`${isMobile ? '' : 'overflow-hidden'} grid ${
          isLegalsPage
            ? 'grid-cols-1 overflow-y-auto scrollbar-hide'
            : 'grid-cols-2'
        } `}
      >
        <div
          className={`relative z-50 flex flex-col justify-center ${
            isTablet ? 'col-span-2' : 'col-span-1'
          } h-full px-8 py-6 scrollbar-hide md:py-8 lg:px-10`}
        >
          <Outlet context={[termsAccepted, setTermsAccepted]} />
        </div>
        {!isTablet && showCarousel && (
          <div className='col-span-1 h-full overflow-hidden'>
            <Carousel
              infiniteLoop
              showArrows={false}
              showThumbs={false}
              showIndicators={false}
              showStatus={false}
              autoPlay
              swipeable={false}
              axis='horizontal'
              interval={4500}
              transitionTime={1000}
              animationHandler='fade'
            >
              {slides.map((slide, index) => (
                <div key={index}>
                  <img
                    src={slide}
                    alt='slide'
                    className='flex h-[calc(100vh-208px)] w-full object-cover'
                  />
                </div>
              ))}
            </Carousel>
          </div>
        )}
      </div>

      <Footer />
    </div>
  )
}

export default LayoutCentered
