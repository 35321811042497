import React, {
  cloneElement,
  type FC,
  type HTMLAttributes,
  type ReactElement,
  type SVGProps,
} from 'react'
import { cva, cx, type VariantProps } from 'cva'

const icon = cva(['flex', 'items-center', 'justify-center', 'fill-white'], {
  variants: {
    size: {
      normal: ['w-5', 'h-5'],
    },
  },
  defaultVariants: {
    size: 'normal',
  },
})

type IconProps = HTMLAttributes<HTMLDivElement> &
  VariantProps<typeof icon> & {
    children: ReactElement<SVGProps<SVGSVGElement>>
  }

const Icon: FC<IconProps> = ({ size, children, className, ...props }) => {
  return (
    <div className={cx(icon({ size }), className)} {...props}>
      {cloneElement(children, {
        className: `${children.props.className} h-full`,
      })}
    </div>
  )
}

export default Icon
