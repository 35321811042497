import {
  faArchive,
  faBars,
  faClose,
  faSignOut,
  faStamp,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { signOut } from 'firebase/auth'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'

import { Button, MenuItem } from '.'
import { auth } from '../firebase'
import { useMediaQuery, useToggleSidebar, useUser } from '../hooks'
import ColorBand from './ColorBand'

const Sidebar = () => {
  const { t } = useTranslation('common')

  const navigate = useNavigate()

  const isTablet = useMediaQuery('(max-width: 1024px)')
  const { sidebarOpen, setSidebarOpen } = useToggleSidebar()

  //const [isSubMenuOpened, setIsSubMenuOpened] = useState<boolean>(true)

  const { user } = useUser()

  const sidebarMenu = [
    {
      label: 'sidebar.digital_collectibles',
      icon: faArchive,
      path: '/',
      disabled: false,
      active: false,
    },
    {
      label: 'sidebar.digital_collectibles_submenu.registration_certificates',
      icon: faStamp,
      path: '/',
      disabled: false,
      isSubmenuItem: true,
      active: true,
    },
    // {
    //   label: 'sidebar.digital_collectibles_submenu.nft',
    //   icon: faMedal,
    //   path: '/registration-certificates',
    //   disabled: true,
    // },
    // {
    //   label: 'sidebar.digital_collectibles_submenu.transcript',
    //   icon: faBook,
    //   path: '/transcript',
    //   disabled: true,
    // },
    // {
    //   label:
    //     'sidebar.digital_collectibles_submenu.participation_certificates',
    //   icon: faCertificate,
    //   path: '/participation-certificates',
    //   disabled: true,
    // },
    // {
    //   label: 'sidebar.digital_collectibles_submenu.skills_certificates',
    //   icon: faCogs,
    //   path: '/skills-certificates',
    //   disabled: true,
    // },
  ]

  /* const getIsMenuItemActive = (path: string) => {
    return location.pathname === path
  } */

  const switchPage = (path: string) => {
    navigate(path)

    if (sidebarOpen) {
      setSidebarOpen(false)
    }
  }

  const handleLogout = () => {
    signOut(auth).then(() => {
      navigate('/auth')
    })
  }
  const goToHomepage = () => navigate('/')
  return (
    <aside className='flex h-screen w-full shrink-0 flex-col space-y-10 bg-white lg:w-[20rem] lg:shadow-[0px_40px_30px_10px_rgba(0,0,0,0.05)]'>
      <header className={`w-full bg-blue-dark`}>
        <div
          className={`flex w-full ${
            isTablet ? 'justify-between px-6' : 'justify-center'
          } py-5`}
        >
          <div
            className='flex w-full cursor-pointer justify-center'
            onClick={goToHomepage}
          >
            <img src='/assets/images/logo-white.png' className='h-14' />
          </div>

          {isTablet && (
            <Button
              mode='icon'
              intent='ghost-contrary'
              onClick={() => setSidebarOpen(!sidebarOpen)}
            >
              <FontAwesomeIcon icon={sidebarOpen ? faClose : faBars} />
            </Button>
          )}
        </div>

        <ColorBand />
      </header>

      <nav className='flex w-full grow flex-col px-4 pb-8'>
        <ul className='w-full grow space-y-2'>
          {sidebarMenu.map((item) => {
            //const hasSubMenu = item.submenu && item.submenu.length > 0

            return (
              <div className='text-blue-stroke' key={item.path}>
                <MenuItem
                  icon={item.icon}
                  isDisabled={item.disabled}
                  onClick={() =>
                    /* hasSubMenu
                      ? setIsSubMenuOpened((prev) => !prev)
                      :  */ switchPage(item.path)
                  }
                  isActive={
                    /*  !hasSubMenu ? getIsMenuItemActive(item.path) :  */ item.active
                  }
                  hasSubMenu={/* hasSubMenu ||  */ false}
                  isSubMenuItem={item.isSubmenuItem}
                >
                  {t(item.label)}
                </MenuItem>

                {/*  {hasSubMenu && isSubMenuOpened && (
                  <ul className='space-y-1 pt-2'>
                    {item.submenu.map((subitem) => (
                      <MenuItem
                        className='text-blue-dark'
                        isDisabled={subitem.disabled}
                        key={subitem.path}
                        icon={subitem.icon}
                        onClick={() => switchPage(subitem.path)}
                        isActive={getIsMenuItemActive(subitem.path)}
                        isSubMenuItem
                      >
                        {t(subitem.label)}
                      </MenuItem>
                    ))}
                  </ul>
                )} */}
              </div>
            )
          })}
        </ul>

        <ul>
          <MenuItem
            isBottomItem
            icon={faUser}
            className='!border-0 text-blue-dark'
          >
            {user.email ? user.email : 'studente@studente.luiss'}
          </MenuItem>

          <MenuItem
            isBottomItem
            icon={faSignOut}
            onClick={handleLogout}
            className='!border-0 text-blue-dark'
          >
            Logout
          </MenuItem>
        </ul>
      </nav>
    </aside>
  )
}

export default Sidebar
