import { Dispatch, SetStateAction, useState } from 'react'
import {
  DialogContent,
  DialogRoot,
  DialogTitle,
  DialogTrigger,
} from '@radix-ui/themes'

import { useMediaQuery } from '../hooks'
import Tos from '../pages/ToS'
import Button from './Button'
import CustomCheckbox from './CustomCheckbox'

const TermsAndConditions = ({
  setTermsAccepted,
  termsAccepted,
}: {
  termsAccepted: {
    cookiePolicy: 'accepted' | 'declined' | ''
    privacyPolicy: boolean
  }

  setTermsAccepted: Dispatch<
    SetStateAction<{
      cookiePolicy: 'accepted' | 'declined' | ''
      privacyPolicy: boolean
    }>
  >
}) => {
  const [modalData, setModalData] = useState({
    isChecked1: false,
    isChecked2: false,
  })
  const isTablet = useMediaQuery('(max-width: 898px)')
  const isMobile = useMediaQuery('(max-width: 640px)')

  const toggleModal = () => {
    setTermsAccepted({
      ...termsAccepted,
      privacyPolicy: true,
    })
  }

  const handleCheckboxChange = (checkboxName: keyof typeof modalData) => {
    setModalData((prevState) => ({
      ...prevState,
      [checkboxName]: !prevState[checkboxName],
    }))
  }
  const buttonIsDisabled = !modalData.isChecked1 || !modalData.isChecked2
  return (
    <DialogRoot
      open={
        (termsAccepted.cookiePolicy && !termsAccepted.privacyPolicy) || false
      }
    >
      <DialogContent
        onPointerDownOutside={(e) => e.preventDefault()}
        style={{ maxWidth: '90%', borderRadius: '0' }}
        className={`overflow-hidden text-left ${
          isTablet ? 'inset-0' : 'bottom-10'
        } border-blue-darkp-8 bg-gray-50 dark:border-gray-600 dark:bg-gray-700 fixed z-50 m-auto flex ${
          isMobile ? 'h-4/5' : 'h-2/5'
        } flex-col items-center border border-[#2A3978] px-8 py-5 shadow-lg md:flex-col`}
      >
        <DialogTitle className='w-full pb-6 text-xl font-semibold text-blue-dark'>
          Terms and Conditions
        </DialogTitle>
        <div className='overflow-y-auto scrollbar-hide'>
          <Tos isPopup />
        </div>
        <div
          className={`flex ${
            isTablet ? 'flex-col space-y-2' : 'space-x-8'
          } w-full justify-start py-6`}
        >
          <CustomCheckbox
            label='Accetto le predette condizioni di contratto'
            isChecked={modalData.isChecked1}
            onChange={() => handleCheckboxChange('isChecked1')}
          />
          <CustomCheckbox
            label='Accetto specificatamente le predette clausole del contratto'
            isChecked={modalData.isChecked2}
            onChange={() => handleCheckboxChange('isChecked2')}
          />
        </div>
        <div
          className={`flex ${
            isTablet ? 'justify-center' : 'flex-row justify-end'
          } w-full`}
        >
          <DialogTrigger>
            <Button
              intent={buttonIsDisabled ? 'disabled' : 'primary'}
              disabled={buttonIsDisabled}
              onClick={() => {
                setTermsAccepted({
                  ...termsAccepted,
                  privacyPolicy: true,
                })
                localStorage.setItem(
                  'termsAccepted',
                  JSON.stringify({
                    ...termsAccepted,
                    privacyPolicy: true,
                  }),
                )
              }}
            >
              <div
                onClick={() => toggleModal()}
                className='flex items-center justify-center space-x-2 text-[1rem]'
              >
                <span className='font-primary-bold'>Accept</span>
              </div>
            </Button>
          </DialogTrigger>
        </div>
      </DialogContent>
    </DialogRoot>
  )
}

export default TermsAndConditions
